<template>
  <h4>You are in home.</h4>
</template>

<script>
import store from '../store/store.js';
export default {
  mounted() {
    console.log(store.getters.currentUser);
  },
};
</script>
